import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "addProduct",
    "collapsible",
    "eventPrice",
    "eventPriceContainer",
    "freeEventMessage",
    "productIdInput",
    "productPrice",
    "productSelect"
  ]

  static values = { programProductOptions: Array }

  productIdInputTargetConnected(element) {
    this.removeSelectedProducts()
    this.activateCollapsibleComponents()
    this.updateEventPrice()
  }

  productIdInputTargetDisconnected(element) {
    this.addUnselectedProducts()
    this.updateEventPrice()
  }

  // Removes selected products from the dropdown
  removeSelectedProducts() {
    const tableIds = this.productIdInputTargets.map(function(e) { return e.value })

    for (var i = this.productSelectTarget.length - 1; i >= 0; i--) {
      const selectVal = this.productSelectTarget.options[i].value
      if (!selectVal == "" && tableIds.indexOf(selectVal) >= 0) {
        this.productSelectTarget.remove(i);
      }
    }

    this.handleProductSelectChange()
  }

  // Adds products removed from the table back to the dropdown
  addUnselectedProducts() {
    const tableIds = this.productIdInputTargets.map(function(e) { return e.value })
    const selectIds = [...this.productSelectTarget.options].map(function(e) { return e.value })

    for (var i = 0; i < this.programProductOptionsValue.length; i++) {
      const productId = this.programProductOptionsValue[i][1].toString()
      if (tableIds.indexOf(productId) < 0 && selectIds.indexOf(productId) < 0) {
        const label = this.programProductOptionsValue[i][0]
        const value = this.programProductOptionsValue[i][1]
        this.productSelectTarget.add(new Option(label, value));
      }
    }
  }

  handleProductSelectChange() {
    if(this.productSelectTarget.value == "") {
      this.addProductTarget.disabled = true
    } else {
      this.addProductTarget.disabled = false
    }
  }

  updateEventPrice() {
    if(this.hasProductIdInputTarget){
      this.freeEventMessageTarget.classList.add("hide")
      this.eventPriceContainerTarget.classList.remove("hide")
      this.handleProductPriceChange()
    } else {
      this.freeEventMessageTarget.classList.remove("hide")
      this.eventPriceContainerTarget.classList.add("hide")
    }
  }

  handleProductPriceChange() {
    const newPrice = this.productPriceTargets.reduce((partialSum, target) => partialSum + parseFloat(target.value), 0);
    this.eventPriceTarget.innerText = this.formatCurrency(newPrice)
  }

  activateCollapsibleComponents() {
    componentHandler.upgradeAllRegistered()
    let components = this.collapsibleTargets

    for (var i = 0, j = components.length; i < j; i++) {
      components[i].MaterialExtCollapsible.collapse()
    }
  }

  formatCurrency(value){
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })
    return formatter.format(value)
  }
}
