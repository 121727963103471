/* global MdlButton */
/* eslint no-undef: "error"*/
import { Textfield } from 'react-mdl'
import classNames from 'classnames'
import _uniqueId from 'lodash/uniqueId'
const { Fragment, useState } = React
const _ = window._

const blankOption = {
  id: '',
  content: '',
}

const defaultPrevented = (fn) => (event) => {
  event.preventDefault()

  return fn(event)
}

const normalize = (locale) => {
  if (locale === 'en-US') return ''

  const prefix = '_'
  const content = locale.replace('-', '_').toLowerCase()

  return `${prefix}${content}`
}

const Select = ({
  className,
  error,
  includeBlankOption,
  inputId,
  label,
  name,
  options,
  style,
  selectedId,
  onChange,
}) => {
  const fullOptions = includeBlankOption ? [blankOption, ...options] : options
  const idArray = fullOptions.map((opt) => opt.id)

  const indexOfSelected = idArray.indexOf(selectedId)
  const selected = indexOfSelected === -1 ? 0 : indexOfSelected

  const [selectedIdx, setSelectedIdx] = useState(selected)

  const handleChange = defaultPrevented((e) => {
    setSelectedIdx(idArray.indexOf(e.target.value))
    if (onChange) {
      onChange(e)
    }
  })

  return (
    <div
      className={classNames('mdl-textfield mdl-js-textfield mdl-textfield--floating-label', {
        'is-invalid': !!error,
        [className]: !!error,
      })}
      style={style || {}}>
      {/* onBlur is required to pass eslint, but it's probably not important */}
      <select
        id={inputId}
        className="mdl-textfield__input select"
        name={name}
        onBlur={() => {}}
        onChange={handleChange}
        value={fullOptions[selectedIdx].id}>
        {fullOptions.map((option, i) => {
          return (
            <option key={i} value={option.id}>
              {option.content}
            </option>
          )
        })}
      </select>
      <label className="mdl-textfield__label" htmlFor={inputId}>
        {label}
      </label>
      {error && <span className="mdl-textfield__error">{error}</span>}
    </div>
  )
}

const HiddenInput = ({ value, name }) => {
  return <input aria-hidden="true" type="text" hidden value={value} name={name} readOnly />
}

const DestroyedGroupInputs = ({ nameGroup }) => {
  const baseName = `event_settings[program_event_names_attributes][]`
  return (
    <Fragment>
      <HiddenInput value={nameGroup.id} name={`${baseName}[id]`} />
      <HiddenInput value="1" name={`${baseName}[_destroy]`} />
    </Fragment>
  )
}

const LocalizedInputs = ({ baseName, group, showLocale }) => {
  return (
    <Fragment>
      <HiddenInput value={group.id} name={`${baseName}[][id]`} />
      {group.localized_attributes.map((localized, index) => {
        const inputId = `${localized.key}-${index}`
        const label = showLocale
          ? I18n.t('events.views.program_event_settings.event_settings_event_name', {
              locale_code: localized.locale_code,
            })
          : I18n.t('events.views.program_event_settings.event_settings_event_name_single_language')
        return (
          <Textfield
            floatingLabel
            key={`name_input-${localized.key}-${index}`}
            id={inputId}
            label={label}
            name={`${baseName}[][${localized.key}]`}
            defaultValue={localized.value}
            placeholder={label}
            className="mr-5"
          />
        )
      })}
    </Fragment>
  )
}

const EventNameGroup = ({ identifier, nameGroup, showLocale, handleRemoveName }) => {
  const baseName = 'event_settings[program_event_names_attributes]'
  return (
    <tr>
      <td className="mdl-data-table__cell--non-numeric px-10">
        <LocalizedInputs group={nameGroup} baseName={baseName} showLocale={showLocale} />
      </td>
      <td>
        <MdlButton
          className="mdl-button mdl-js-button mdl-button--icon mdl-button--transparent mr-0"
          onClick={(e) => handleRemoveName(identifier)}
        >
          <span className="material-icons" aria-label="remove">
            close
          </span>
        </MdlButton>
      </td>
    </tr>
  )
}

const AddNameButton = ({ onClick }) => {
  return (
    <MdlButton className="mdl-button--colored" onClick={onClick}>
      <span className="material-icons" aria-hidden="true">
        add
      </span>
      {I18n.t('events.views.event_settings.add_name')}
    </MdlButton>
  )
}

const emptyNameGroup = (supportedLocales) => {
  return {
    id: '',
    groupIdentifier: _uniqueId("grouping-"),
    localized_attributes: supportedLocales.map((locale) => {
      const suffix = normalize(locale.iso_code)
      const attribute = `value${suffix}`
      return {
        locale_code: locale.iso_code,
        key: attribute,
        [attribute]: '',
      }
    }),
  }
}

const EventSettingsEventNames = ({
  eventNameSettingOptions,
  eventNameSetting,
  configuredEventNames,
  supportedLocales,
}) => {
  const initialEventNames = configuredEventNames.map((nameGroup) => {
    nameGroup.groupIdentifier = _uniqueId('grouping-')
    return nameGroup
  })
  const [nameGroups, setNameGroups] = useState(initialEventNames)
  const [selectedEventNameSetting, setSelectedEventNameSetting] = useState(eventNameSetting)

  const destroyedGroups = nameGroups.filter((nameGroup) => nameGroup._destroy)
  const activeGroups = nameGroups.filter((nameGroup) => !nameGroup._destroy)

  const handleAddName = defaultPrevented(() => {
    setNameGroups([...nameGroups, emptyNameGroup(supportedLocales)])
  })

  const handleRemoveName = (identifier) => {
    const newNameGroups = [...nameGroups]
    const groupIndex = nameGroups.findIndex((nameGroup) => nameGroup.groupIdentifier == identifier)

    if (newNameGroups[groupIndex].id) {
      newNameGroups[groupIndex]._destroy = true
    } else {
      newNameGroups.splice(groupIndex, 1)
    }
    setNameGroups(newNameGroups)
  }

  const multiLanguage = supportedLocales.length > 1

  return (
    <Fragment>
      <p>{I18n.t('events.views.program_event_settings.event_name_options_note')}</p>
      <Select
        onChange={(e) => setSelectedEventNameSetting(e.target.value)}
        name="event_settings[event_name_setting]"
        inputId={_.uniqueId('select-')}
        selectedId={eventNameSetting}
        label={'Event Name Setting'}
        options={eventNameSettingOptions}
        includeBlankOption
      />

      <div id="event_name_list_container">
        {destroyedGroups.map((group) => (
          <DestroyedGroupInputs nameGroup={group} key={group.groupIdentifier} />
        ))}

        <div className="u-margin--bottom-20">
          <table
            id="event_name_list_table"
            className="mdl-data-table mdl-js-data-table block--full text--darkgray"
          >
            <tbody className="list">
              {activeGroups.map((nameGroup) => (
                <EventNameGroup
                  key={nameGroup.groupIdentifier}
                  identifier={nameGroup.groupIdentifier}
                  nameGroup={nameGroup}
                  showLocale={multiLanguage}
                  handleRemoveName={handleRemoveName}
                />
              ))}
            </tbody>
          </table>
        </div>
        {selectedEventNameSetting === 'configured_name' ? (
          <AddNameButton onClick={handleAddName} />
        ) : null}
      </div>
    </Fragment>
  )
}

export default EventSettingsEventNames
