import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "courseNameInput", "courseNameWrapper" ]

  onCollectionChanged(event) {
    this.disableCourseName();

    let certificationCollectionId = event.target.value;

    if (certificationCollectionId != "") {
      let courseNamesURL = new URL(this.courseNameInputTarget.dataset.url);
      courseNamesURL.searchParams.set("certification_collection_id", certificationCollectionId)

      fetch(courseNamesURL)
        .then(response => response.json())
        .then(courseNames => {
          this.enableCourseName(courseNames);
        }
      );
    }
  }

  clearCourseNames() {
    for (let optionsIndex = 0; optionsIndex <= this.courseNameInputTarget.options.length; optionsIndex++) {
      this.courseNameInputTarget.remove(0);
    }
  }

  disableCourseName() {
    this.clearCourseNames();

    this.courseNameWrapperTarget.classList.add("disabled");
    this.courseNameWrapperTarget.classList.add("is-disabled");
    this.courseNameInputTarget.disabled = true;
    this.courseNameInputTarget.classList.add("disabled");
  }

  enableCourseName(courseNames) {
    let select = this.courseNameInputTarget;
    let wrapper = this.courseNameWrapperTarget;

    select.disabled = false;
    select.classList.remove("disabled");
    wrapper.classList.remove("disabled");
    wrapper.classList.remove("is-disabled");

    this.clearCourseNames();

    let noCourseLabel = select.dataset.blankLabel;
    select.options[0] = new Option(noCourseLabel, "");

    courseNames.forEach(courseName => {
      select.options[select.options.length] = new Option(courseName, courseName);
    });
  }
}
